import { keys } from "lodash";

export const customerStatus = {
  active: ["active", "Aktif"],
  deactivated: ["deactivated", "Tidak Aktif"],
};

export const membershipCustomerStatus = {
  active: ["active", "Aktif"],
  inactive: ["inactive", "Tidak Aktif"],
  finished: ["finished", "Selesai"],
  trial: ["trial", "Trial"],
  stopped: ["stopped", "Berhenti"],
};

export const paymentlinkStatus = {
  active: ["active", "Aktif"],
  unpaid: ["unpaid", "Belum dibayar"],
  paid: ["paid", "Paid"],
  closed: ["closed", "Tutup"],
  deleted: ["deleted", "Tutup"],
  archived: ["archived", "Arsip"],
};

export const discountStatus = {
  active: ["active", "Aktif"],
  inactive: ["inactive", "Tutup"],
};

export const fundraisingStatus = {
  active: ["active", "Aktif"],
  closed: ["closed", "Tutup"],
};

export const paymentlinkTypeStatus = {
  payment_request: ["payment_request", "Penagihan"],
  payme: ["payme", "Pay Me"],
  subscription: ["subscription", "Berlangganan"],
  generic_link: ["generic_link", "Link Pembayaran"],
  digital_product: ["digital_product", "Produk Digital"],
  webinar: ["webinar", "Webinar"],
};

export const paymentlinkTransactionStatus = {
  created: ["created", "Belum dibayar"],
  verifying: ["verifying", "Sedang diverifikasi"],
  paid: ["paid", "Paid"],
  failed: ["failed", "Pembayaran Gagal"],
};

export const subscriptionStatus = {
  active: ["active", "Aktif"],
  inactive: ["inactive", "Tidak Aktif"],
  closed: ["closed", "Tutup"],
};

export const subscriptionCustomerStatus = {
  active: ["active", "Aktif"],
  inactive: ["inactive", "Tidak Aktif"],
  trial: ["trial", "Trial"],
  stopped: ["stopped", "Berhenti"],
  finished: ["finished", "Selesai"],
};

export const getDefaultStatus = (obj) => {
  return obj[keys(obj)[0]];
};

export const getStatusLabel = (obj, value) => {
  return obj[value] ? obj[value][1] : getDefaultStatus(obj)[1];
};

export const paymentStatus = {
  paid: ["paid", "Paid"],
  settled: ["settled", "Settled"],
  unpaid: ["unpaid", "Unpaid"],
  pending: ["unpaid", "Unpaid"],
  expired: ["expired", "Expired"],
};

export const orderStatus = {
  PACKED: ["PACKED", "Dikemas"],
  WAITING: ["WAITING", "Menunggu Kurir"],
  SHIPMENT: ["SHIPMENT", "Dalam Pengiriman"],
  FINISH: ["FINISH", "Selesai"],
  CANCELED: ["CANCELED", "Dibatalkan"],
};

export const submissionStatus = {
  SUBMITTED: { color: "primary", text: "Belum Dinilai" },
  NOT_SUBMITTED: { color: "secondary", text: "Belum Submit" },
  ACCEPTED: { color: "success", text: "Sudah Dinilai" },
};
