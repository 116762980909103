// import moment from '@/lib/moment';
import { formatDistance, differenceInDays, differenceInMinutes, differenceInHours } from 'date-fns';
import { id } from 'date-fns/locale';
import { localeMap } from '@/lib/utils';

export const translatePeriod = (period, t) => {
  if (period === 'monthly' && t) {
    return t('word.month');
  } else if (period === 'monthly') {
    return 'bulan';
  } else if (period === 'weekly' && t) {
    return t('word.week');
  } else if (period === 'weekly') {
    return 'minggu';
  }
  return '';
};

export const getSubscriptionFrequency = (
  billingCycle,
  billingCycleCustomPeriod,
  billingCycleCustomPeriodTime,
  t,
) => {
  let pertext = '';
  if (billingCycle === 'custom') {
    pertext = billingCycleCustomPeriodTime + ' ' + translatePeriod(billingCycleCustomPeriod, t);
  } else {
    pertext = translatePeriod(billingCycle, t);
  }
  return pertext;
};

// export const getUserTimezone = () => moment.tz.guess();

// export const convertDateToDays = (date = new Date().valueOf(), isRemaining = false, translation) => {
//   const sourceDate = moment(date);
//   const currentDate = moment();
//   const diff = sourceDate.diff(currentDate, 'days');

//   if (isRemaining && diff >= 0) {
//     return `${currentDate.to(sourceDate, true)} ${
//       translation ? translation('word.concatDonate') : 'lagi'
//     }`;
//   }

//   return isRemaining
//     ? `${translation ? translation('fundraising.itsOver') : 'Sudah Berakhir'}`
//     : `${currentDate.to(sourceDate, true)} ${translation ? translation('word.ago') : 'yang lalu'}`;
// };

export const convertDateToDays = (
  date,
  isRemaining = false,
  translation,
  locale = id
) => {
  const sourceDate = new Date(date);
  const currentDate = new Date();
  const diff = differenceInDays(sourceDate, currentDate);

  if (isRemaining) {
    return diff >= 0
      ? `${formatDistance(currentDate, sourceDate, { locale: id })} ${
          translation ? translation('word.concatDonate') : 'lagi'
        }`
      : translation
      ? translation('fundraising.itsOver')
      : 'Sudah Berakhir';
  }

  return `${formatDistance(sourceDate, currentDate, { locale })} ${
    translation ? translation('word.ago') : 'yang lalu'
  }`;
};

// export const countdownDate = (date = new Date(), useBahasa = false) => {
//   const currentDate = moment();
//   const milSec = date - currentDate;
//   const d3 = new Date(milSec);
//   const nrDays = Math.floor(d3 / 1000 / 60 / 60 / 24),
//     nrHours = Math.floor(d3 / 1000 / 60 / 60) % 24,
//     nrMin = Math.floor(d3 / 1000 / 60) % 60;

//   if (useBahasa) {
//     return `${nrDays} hari ${nrHours} jam ${nrMin} menit`;
//   }

//   return `${nrDays} days ${nrHours} hours ${nrMin} min`;
// };

export const countdownDate = (date = new Date(), useBahasa = false) => {
  const currentDate = new Date();

  // Calculate time differences
  const nrDays = differenceInDays(date, currentDate);
  const nrHours = differenceInHours(date, currentDate) % 24;
  const nrMin = differenceInMinutes(date, currentDate) % 60;

  if (useBahasa) {
    return `${nrDays} hari ${nrHours} jam ${nrMin} menit`;
  }

  return `${nrDays} days ${nrHours} hours ${nrMin} min`;
};
