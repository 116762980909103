import { Duration, format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { ImageLoader, ImageLoaderProps } from 'next/image';
import { enUS, id, Locale } from 'date-fns/locale';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const imageLoader = ({ src, width, quality }: any ) => {
  if (process.env.MAYAR_ENV === 'local' || process.env.NEXT_PUBLIC_MAYAR_ENV_EXACT === 'local') return src
  // console.log({testenv: process.env.NEXT_PUBLIC_APP_STATIC_URL, ee: process.env.APP_STATIC_URL})
  const urlImage = process.env.APP_STATIC_URL || process.env.NEXT_PUBLIC_APP_STATIC_URL
  return urlImage ? `https://${urlImage}/_next/image/?url=${src}&w=${width}&q=${quality || 75}` : src
}

export const localeMap: Record<string, Locale>= {
  en: enUS,
  id: id,
};

export const DEFAULT_LOCALE = 'id';

export function formatDate(date: string | number | Date, formatString: string, locale: Locale) {
  if (!date) return '';
  
  const dateObj = typeof date === 'string' ? parseISO(date) : date;
  return format(dateObj, formatString, { locale });
}

function getIndonesianTimezoneLabel(timezone: string) {
  if (timezone.includes('GMT+07')) return 'WIB'
  else if (timezone.includes('GMT+08')) return 'WITA'
  else if (timezone.includes('GMT+09')) return 'WIT'
  else return ''
}

export function formatDateWithLocalTimezone(date: string | number | Date, formatString: string, locale: Locale) {
  if (!date) return '';
  
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  const dateObj = typeof date === 'string' ? parseISO(date) : date;
  
  const zonedDate = toZonedTime(dateObj, userTimezone);
  const tzLabel = getIndonesianTimezoneLabel(String(zonedDate));

  return format(zonedDate, formatString, { locale }) + ` ${tzLabel}`;
}

export function formatDuration(duration: Duration) {
  const parts = [];
  if (duration.hours) parts.push(`${duration.hours} Jam`);
  if (duration.minutes) parts.push(`${duration.minutes} Menit`);
  return parts.join(" ") || "0 Menit"; // Default if duration is empty
}

export const getTimezoneUser = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
