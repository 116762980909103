import DOMPurify from 'isomorphic-dompurify';
// import { sanitize } from 'dompurify';
// import sanitizeHtml from 'sanitize-html';

export const sanitizeDOM = (dirty) => {
  return DOMPurify.sanitize(dirty, {
    ADD_TAGS: ['iframe'],
    ALLOWED_ATTR: ['href', 'src', 'style', 'allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target', 'width', 'height'],
  });

  // if (!dirty) return '';
  
  // return sanitizeHtml(dirty, {
  //   allowedTags: sanitizeHtml.defaults.allowedTags.concat(['iframe']),
  //   allowedAttributes: {
  //     ...sanitizeHtml.defaults.allowedAttributes,
  //     '*': ['href', 'style', 'target', 'width', 'height'],
  //     'iframe': ['src', 'allow', 'allowfullscreen', 'frameborder', 'scrolling']
  //   }
  // });
};

export const sanitizeDOMIgnoreMedia = (dirty) => {
  return DOMPurify.sanitize(dirty, {
    FORBID_TAGS: ['iframe', 'img'],
    ALLOWED_ATTR: ['href', 'src', 'style', 'allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target', 'class'],
  });

  // return sanitizeHtml(dirty, {
  //   allowedTags: sanitizeHtml.defaults.allowedTags.filter(tag => 
  //     tag !== 'iframe' && tag !== 'img'
  //   ),
  //   allowedAttributes: {
  //     '*': [
  //       'href',
  //       'src',
  //       'style',
  //       'allow',
  //       'allowfullscreen',
  //       'frameborder',
  //       'scrolling',
  //       'target',
  //       'class',
  //     ]
  //   }
  // });
};

export const sanitizeStrict = (dirty) => {
  return DOMPurify.sanitize(dirty);
  // return sanitizeHtml(dirty, {
  //   allowedTags: [],      // Allow no HTML tags
  //   allowedAttributes: {} // Allow no attributes
  // });
};

export const sanitizeWhatsAppLink = (whatsappLink) => {
  const match = whatsappLink.match(/(https:\/\/api.whatsapp.com\/send\?phone=)(\+\d+)/);
  if (match) {
    const sanitizedLink = match[1] + match[2].replace('+', ''); // Remove the plus sign
    return sanitizedLink;
  }
  return whatsappLink; // Return the original link if it doesn't match the pattern
}
